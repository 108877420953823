(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Matter"));
	else if(typeof define === 'function' && define.amd)
		define(["Matter"], factory);
	else if(typeof exports === 'object')
		exports["ENABLE3D"] = factory(require("Matter"));
	else
		root["ENABLE3D"] = factory(root["Matter"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__626__) {
return 